<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-tooltip
          class="item"
          effect="dark"
          content="增加服务模型"
          placement="top-start"
        >
          <el-button  type="primary" @click="addPeoBtn">增加服务模型</el-button>
        </el-tooltip>
        <!-- <el-tooltip
          class="item"
          effect="dark"
          content="增加服务价格"
          placement="top-start"
        >
          <el-button  type="primary" @click="addPeoPriceBtn">增加服务价格</el-button>
        </el-tooltip> -->
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
            <el-table-column
          align="center"
          prop="mould_id"
          label="模型ID"
        >
        </el-table-column>
            <el-table-column
          align="center"
          prop="mould_name"
          label="服务名称"
        >
        </el-table-column>
     
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看服务模型详情"
              placement="top-start"
            >
              <el-button
                icon="el-icon-search"
                @click="lookSite(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改站点"
              placement="top-start"
            >
              <el-button
                icon="el-icon-edit"
                type="primary"
                @click="updataPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除服务模型"
              placement="top-start"
            >
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delPicture(scope.row.mould_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
     <!-- 增加场地服务标题 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
       width="1500px"
    >
    <div slot="title" v-if="updataFalg" class="dialog-footer" >
        <div class="title" >修改服务模型</div>
      </div>
       <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
        <div class="title" >服务模型规格</div>
      </div>
      <div slot="title" v-else class="dialog-footer" >
        <div class="title" >增加服务模型</div>
      </div>
      <div class="diaBox">
      <el-form :model="form" ref="ruleForm"  :rules="rules">
                       <el-form-item  label="模型名称" :label-width="formLabelWidth" prop="mould_name">
                                 <el-input v-model="form.mould_name"></el-input>
  </el-form-item> 
      </el-form>
       </div>
       <el-tooltip
          class="item"
          effect="dark"
          content="新增规则"
          placement="top-start"
        >
        </el-tooltip>
        <template v-if="lookFalg">
          <div class="title" >模型规格</div>
        <el-button   @click="addSpecBtn">＋ 新增规格</el-button>
        <el-row   style="margin-top: 20px;">
  <el-col :span="3"><div class="grid-content bg-purple"><span>规格名称</span></div></el-col>
  <el-col :span="3"><div class="grid-content bg-purple"><span>排序</span></div></el-col>
  <el-col :span="12"><div class="grid-content bg-purple"><span>规格值</span></div></el-col>
  <el-col :span="6"><div class="grid-content bg-purple"><span>操作</span></div></el-col>
</el-row>
<el-row   v-for="(item1,index) in formSpecValue" :key="index">
  <el-col :span="3"><div :class="[index%2==0?'grid-content1 bg-purple1':'grid-content2 bg-purple2']"><span>
    <el-input v-model="item1.spec_name" style="width: 100px;"></el-input>
  </span></div></el-col>
  <el-col :span="3"><div :class="[index%2==0?'grid-content1 bg-purple1':'grid-content2 bg-purple2']"><span>
    <el-input v-model="item1.spec_sort" style="width: 80px;" type="number"></el-input>
  </span></div></el-col>
  <el-col :span="12"><div :class="[index%2==0?'grid-content1 bg-purple1':'grid-content2 bg-purple2']"><span>
    <div style="display: flex;">
      <div >
     
        <span style="position: relative;" v-for="(item2,index1)  in item1.spec_item_list" :key="item2.item_id">
          <i @click="deleteItemValue(index,index1)" class="el-icon-close close" style="position: absolute;right: 6px;top: -15px;z-index: 999;width: 15px;height: 15px;border-radius: 50%;border:1px solid #99a9bf;display: flex;align-items: center;justify-content: center;cursor: pointer;"></i>
      <el-input @change="changge(index,index1)"  v-model="item2.item_name" style="width: 100px;margin: 10px 10px 10px 0"></el-input>
    </span>
      <!-- <el-input v-model="item1.specItemValue"  style="width: 80px;" placeholder="请输入规格值"></el-input> -->
      <el-button  
         @click="specValueAdd(item1,index)"  type="info" plain size="mini">添 加</el-button
        >   
    </div>
    </div>

    </span></div></el-col>
  <el-col :span="6"><div :class="[index%2==0?'grid-content1 bg-purple1':'grid-content2 bg-purple2']"><span>  
    <el-button  
         @click="specAdd(index)" size="mini">添 加</el-button
        >     
        <el-button   
        @click="specUpdate(index)"    size="mini">修 改</el-button
        >
        <el-button   
        @click="specDelDate(index)"  size="mini" >删 除</el-button
        >
  </span></div></el-col>
</el-row>
</template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="addFlag" type="primary" @click="moduleAddDate('ruleForm')"
          >添 加</el-button
        >
        <el-button v-else-if="lookFalg" type="primary" @click="dialogFormVisible = false"
          >确 认</el-button
        >
       <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
          >修 改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  PostServiceMouldList,
  PostServiceMouldAdd,
  PostServiceMouldUpdate,
  PostServiceMouldDelete
} from "@/api/serveList/serveList.js"
import {
    PostSpecList,
    PostSpecAdd,
    PostSpecDelete,
    PostSpecUpdate,
    PostspecItemList,
    PostspecItemAdd,
    PostspecItemUpdate,
    PostspecItemDelete,
    PostServiceMouldDetail
} from "@/api/specificationList/specificationList.js"

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
        var validateMouldName = (rule, value, callback) => {
        if (this.form.mould_name === '') {
          callback(new Error('请输入模型名称'));
        } else {
          callback();
        }
      };
    //这里存放数据
    return {
         rules: {
          mould_name: [
            { validator: validateMouldName, trigger: 'blur' }
          ],      
        },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      formSpec:[],
      formSpecValue:[],
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      form:{
        mould_id:'',
        mould_name:'',

      },
      props:{
        children:'sub',
        value:'id',
        label:'name'
      },
      //表格数据
         tableData: [],
         tableSpec:[],
            token: {
        token: window.sessionStorage.getItem("token"),
      },
      formLabelWidth: "100px",
       imgParam: {
        path: "contract/template",
      },
        fileList: [],
        //控制弹窗--新增修改
      dialogFormVisible: false,
      addFlag:false,
      lookFalg:false,
      updataFalg:false,
      mould_id:'',
       options: [],
         options1: [{
          value: 0,
          label: '上门服务模板'
        }, {
          value: 1,
          label: '场地预约模板'
        }],
    };
  },
  //监听属性 类似于data概念
  computed: {
        imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {
    addPeoPriceBtn(){
      this.$router.push('/servePriceList')
    },
    //删除属性规格值
    deleteItemValue(index,index1){
     // console.log(index,index1);
          this.PostspecItemDelete({
            item_id:this.formSpecValue[index].spec_item_list[index1].item_id
          })
          
    },
    //修改属性规格值
    changge(index,index1){
      if(this.formSpecValue[index].spec_item_list[index1].flag){
              this.PostspecItemAdd({
                spec_id:this.formSpecValue[index].spec_item_list[index1].spec_id,
                item_name:this.formSpecValue[index].spec_item_list[index1].item_name
              })
      }else{
        this.PostspecItemUpdate({
          item_id:this.formSpecValue[index].spec_item_list[index1].item_id,
          item_name:this.formSpecValue[index].spec_item_list[index1].item_name,
          item_sort:this.formSpecValue[index].spec_item_list[index1].item_sort,
        })
      }
        
    },
    //商品规格值增加
    specValueAdd(item1,index){
      if(this.formSpecValue[index].spec_item_list.length>0){
        let len=this.formSpecValue[index].spec_item_list.length-1;
        this.formSpecValue[index].spec_item_list.push({
        spec_id:this.formSpecValue[index].spec_id,
        item_id:this.formSpecValue[index].spec_item_list[len].item_id+1,
        item_name: "",
				item_sort: 1,
        flag:true
      })
      }else{
        this.formSpecValue[index].spec_item_list.push({
        spec_id:this.formSpecValue[index].spec_id,
        item_id:1001,
        item_name: "",
				item_sort: 1,
        flag:true
      })
      }
     
        //console.log(index,this.formSpecValue[index],this.formSpecValue[index].indexChild)
    },
    //规格增加
    specAdd(index){
      console.log("增加",index)
      this.PostSpecAdd({
        mould_id:this.formSpecValue[index].mould_id,
        spec_name:this.formSpecValue[index].spec_name,
        spec_sort:this.formSpecValue[index].spec_sort,
      })
    }, 
    //规格修改
    specUpdate(index){
      this.PostSpecUpdate({
        spec_id:this.formSpecValue[index].spec_id,
        spec_name:this.formSpecValue[index].spec_name,
        spec_sort:this.formSpecValue[index].spec_sort,
      })
},
//规格删除
specDelDate(index){
  this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.PostSpecDelete({
              spec_id:this.formSpecValue[index].spec_id
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
  //console.log("删除",index)
},
    //规则增加按钮
    addSpecBtn(){
          this.formSpecValue.push({
              mould_id:this.mould_id,
        spec_nam:'',
        spec_sort:0,
        spec_item_list:[]
          })
    },
    //查看规格
    lookSite(row){
      this.dialogFormVisible=true;
      this.form.mould_name=row.mould_name
          this.lookFalg=true;
          this.mould_id=row.mould_id;
        this.PostSpecList({
          mould_id:row.mould_id
        })
      this.PostServiceMouldDetail({
        mould_id:row.mould_id
      })
        
    },
         //站点修改
         updSiteSer(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
          this.PostServiceMouldUpdate(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
      //修改站点
      updataPicture(item){
        this.dialogFormVisible=true;
          this.updataFalg=true;
          this.form={
            mould_id:item.mould_id,//id
            mould_name:item.mould_name,//类型
          }
      },
     //站点增加
     moduleAddDate(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
          this.PostServiceMouldAdd(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
      //站点删除
      delPicture(mould_id){
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.PostServiceMouldDelete({
              mould_id:mould_id
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
        
        },
    //增加站点
    addPeoBtn(){
      this.dialogFormVisible=true;
          this.addFlag=true;
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
      page: this.getInfo.page_code,
      list_rows: this.getInfo.page_num,
    };
     this.PostServiceMouldList(data);
      this.tableData = [];
     // this.getPeopleList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
      page: this.getInfo.page_code,
      list_rows: this.getInfo.page_num,
    };
     this.PostServiceMouldList(data);
      this.tableData = [];
    //  this.getPeopleList(data);
    },
          exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
        // console.log(response)
      this.form.url = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.url = "";
    },
           //添加事件 退出弹窗
    userClose(e) {
      this.form = this.$options.data().form;
      this.formSpec = this.$options.data().formSpec;
      this.formSpecValue = this.$options.data().formSpecValue;
          this.updataFalg=false;
          this.lookFalg=false;
          this.addFlag=false;
      (this.fileList = []), console.log("close");
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //服务模型详情
    async PostServiceMouldDetail(data){
        let res=await PostServiceMouldDetail(data);
        this.formSpecValue=res.data.result;
        this.formSpecValue.forEach((item)=>{
          item.specItemValue=''
        })
    },
    //规则项列表
      async PostspecItemList(data){
      let res=await PostspecItemList(data);
        this.formSpecValue.push(res.data.result);
        console.log(this.formSpecValue)
        // this.formSpec.forEach((item)=>{
        //   item.mould_id=row.mould_id
        // })
    },
                //增加规格项
                async PostspecItemAdd(dataObj){
         let res=await PostspecItemAdd(dataObj);
         if(res.data.status==1){
            // this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        mould_id:this.mould_id
      };
       this.PostServiceMouldDetail(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
                //删除规格项
                async PostspecItemDelete(dataObj){
         let res=await PostspecItemDelete(dataObj);
         if(res.data.status==1){
            // this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        mould_id:this.mould_id
      };
       this.PostServiceMouldDetail(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
                 //修改规格项
                 async PostspecItemUpdate(dataObj){
         let res=await PostspecItemUpdate(dataObj);
         if(res.data.status==1){
            // this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        mould_id:this.mould_id
      };
       this.PostServiceMouldDetail(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
    //规则列表
    async PostSpecList(data){
      let res=await PostSpecList(data);
         this.formSpecValue=res.data.result;
        //  this.formSpec.forEach((item)=>{
        //          this.PostspecItemList({
        //     spec_id:item.spec_id
        //   })
        //  })
      
    },
             //增加规格
             async PostSpecAdd(dataObj){
         let res=await PostSpecAdd(dataObj);
         if(res.data.status==1){
            // this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success',
         
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        mould_id:this.mould_id,
      };
       this.PostServiceMouldDetail(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
                //删除规格
                async PostSpecDelete(dataObj){
         let res=await PostSpecDelete(dataObj);
         if(res.data.status==1){
            // this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        mould_id:this.mould_id,
      };
       this.PostServiceMouldDetail(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
                 //修改规格
                 async PostSpecUpdate(dataObj){
         let res=await PostSpecUpdate(dataObj);
         if(res.data.status==1){
            // this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        mould_id:this.mould_id,
      };
       this.PostServiceMouldDetail(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
     //服务模型列表
    async PostServiceMouldList(data){
        let res=await PostServiceMouldList(data);
        console.log(res.data)
        this.tableData=res.data.result;
        // this.getInfo={
        //     page_code:res.data.result.last_page,
        //     list_rows:res.data.result.per_page
        // }
           this.exp.num=res.data.result.length;
        //   this.dialogFormVisible=false;
        // console.log(res.data.data);
    },
            //服务模型增加
            async PostServiceMouldAdd(dataObj){
         let res=await PostServiceMouldAdd(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostServiceMouldList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
           //服务模型修改
           async PostServiceMouldUpdate(dataObj){
         let res=await PostServiceMouldUpdate(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostServiceMouldList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
        //服务模型删除
        async PostServiceMouldDelete(dataObj){
         let res=await PostServiceMouldDelete(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostServiceMouldList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      list_rows: this.getInfo.page_num,
    };
     this.PostServiceMouldList(data);
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 150px;
  overflow: hidden;
  text-align: center;
}
.demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf!important;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  /* .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  } */
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }

  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 0px;
    min-height: 36px;
  }
  .bg-purple {
    background: #d3dce6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-content1 {
    border-radius: 0px;
    min-height: 80px;
  }
  .bg-purple1 {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-content2 {
    border-radius: 0px;
    min-height: 80px;
  }
  .bg-purple2 {
    background: #d3dce6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>